import React, { useState, useEffect } from "react";
import s from "./reviews.module.css";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";
import { notificationHandler } from "../../utils/Notification";
import { useLocation } from "react-router-dom";
import { Card, Grid } from "@mui/material";
import { addReview_api, updateReview_api } from "../api/review";
// import { addTestimonial_api, updateTestimonial_api } from "../api/testimonial";
const ReviewAdd = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [name, setname] = useState("");
  const [file, setfile] = useState(null);
  const [rating, setrating] = useState(1);
  const [feedback, setfeedback] = useState("");
  const [email, setemail] = useState();
  const pagetype = location.state.pagetype;

  console.log(location);
  useEffect(() => {
    setname(location?.state?.data?.name);
    setrating(location?.state?.data?.rating);
    setfeedback(location?.state?.data?.feedback);
  }, [location]);

  const role_function = async () => {
    if (pagetype === "Add") {
      const fd = new FormData();
      fd.append("name", name);
      fd.append("review", feedback);
      fd.append("rating", rating);
      fd.append("profile_image", file);
      fd.append("email", email);
      try {
        let res = await addReview_api(fd);
        if (res.data.status) {
          navigate("/review-list");
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
    if (pagetype === "Edit") {
      const fd = new FormData();
      fd.append("name", name);
      fd.append("review", feedback);
      fd.append("rating", rating);
      if (file) {
        fd.append("profile_image", file);
      }
      fd.append("email", email);
      fd.append("id", location?.state?.data?.id);
      try {
        let res = await updateReview_api(fd);
        if (res.data.status) {
          console.log(res);
          navigate("/review-list");
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
  };
  return (
    <>
      <div className="">
        <Card className={s["admin_container"]}>
          <div className={s["title"]} onClick={() => navigate(-1)}>
            <BiArrowBack />
            Back
          </div>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={name}
                  onChange={(e) => setname(e.target.value)}
                  placeholder="Name"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Email</label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                  placeholder="email"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Select Image</label>
                <div className="mr-2">
                  <input
                    type="file"
                    className="form-control"
                    name="img"
                    placeholder=""
                    accept="image/*"
                    onChange={(e) => setfile(e.target.files[0])}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Rating</label>
                <div className="  mr-2">
                  <div className="form-group">
                    <select
                      className="form-control"
                      id="exampleFormControlSelect1"
                      value={rating}
                      onChange={(e) => setrating(e.target.value)}
                      name="role"
                    >
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Feedback</label>
                <textarea
                  type="text"
                  className="form-control"
                  rows={3}
                  name="name"
                  value={feedback}
                  onChange={(e) => setfeedback(e.target.value)}
                  placeholder="Feedback"
                />
              </div>
            </Grid>
          </Grid>

          <div className={s["form-login-btn"]} onClick={() => role_function()}>
            <Custombutton>Submit</Custombutton>
          </div>
        </Card>
      </div>
    </>
  );
};

export default ReviewAdd;
