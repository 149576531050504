import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { tableCellClasses } from "@mui/material/TableCell";
import s from "./event.module.css";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Pagination,
} from "@mui/material";
import { notificationHandler } from "../../utils/Notification";
import Loder from "../../Loder/Loder";
import { BiFilter, BiSearch } from "react-icons/bi";
import DataNotFound from "../ErrorPage/DataNotFound";
import { IoMdAdd } from "react-icons/io";
import {
  deleteAstrologer_api,
  deleteevent_api,
  getAstrologers_api,
  getEventStatus_api,
  getEvent_api,
} from "../api/event";
import { getBaseUrl2 } from "../../utils";

const EventList = () => {
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const [allEvents, setallEvents] = useState([]);
  const [pageCount, setpageCount] = useState(1);
  const [deleteId, setdeleteId] = useState("");
  const [deletePopup, setdeletePopup] = useState(false);
  const [currentGroup, setcurrentGroup] = useState({});
  const [pageLength, setpageLength] = useState();
  const [search, setsearch] = useState("");
  useEffect(() => {
    fetchAllEventFunc();
  }, [pageCount, search]);

  async function fetchAllEventFunc(data) {
    setisLoading(true);
    try {
      const temp = {
        page: pageCount,
        limit: 8,
        search: search.trim(),
      };
      let res = await getEvent_api(temp);
      if (res.data.status) {
        setallEvents(res?.data?.data.eventsData);
        setpageLength(res?.data?.data?.total_page);
        setisLoading(false);
      } else {
        setisLoading(false);
        console.log("status false!");
      }
    } catch (error) {
      console.log(error);
    }
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "var(--clr-theme)",
      color: theme.palette.common.white,
      fontWeight: "bold",
      borderRight: "1px solid #fff",
      overflow: "hidden",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      border: "none",
      borderLeft: "2px solid #00000011",
      "&:last-child": {
        borderRight: "2px solid #00000011",
      },
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    borderBottom: "2px solid #00000011",
  }));

  async function deleteastroFunc() {
    setisLoading(true);
    try {
      let res = await deleteevent_api(deleteId);
      if (res.data.status) {
        setisLoading(false);
        setdeletePopup(false);
        fetchAllEventFunc();
        notificationHandler({ type: "success", msg: res.data.message });
      } else {
        setisLoading(false);
        notificationHandler({ type: "danger", msg: res.data.message });
      }
    } catch (error) {
      console.log(error);
      notificationHandler({ type: "danger", msg: error.message });
    }
  }

  const event_status = async (data) => {
    setisLoading(true);
    try {
      let temp = {
        id: data._id,
        status: data.status == "Active" ? "Inactive" : "Active",
      };
      let res = await getEventStatus_api(temp);
      if (res?.data?.status) {
        setisLoading(false);
        fetchAllEventFunc();
        notificationHandler({ type: "success", msg: res.data.message });
      } else {
        setisLoading(false);
        notificationHandler({ type: "danger", msg: res.data.message });
      }
    } catch (error) {
      setisLoading(false);
      notificationHandler({ type: "danger", msg: error.message });
    }
  };
  return (
    <div className="">
      <div className="beat_heading">
        <div className={s["user-list-heading"]}>
          <div className="user-list-title">
            <h3>Event List </h3>
          </div>
          <div className={s["user-list-search"]}>
            <div className={s["search-box"]}>
              <span style={{ paddingRight: "0.5rem" }}>
                <BiSearch size={23} />
              </span>
              <input
                type="text"
                spellCheck="false"
                onChange={(e) => {
                  setpageCount(1);
                  setsearch(e.target.value);
                }}
                placeholder="Search by name..."
              />
            </div>
            <div className={s["filter-btn"]}>
              <span style={{ paddingRight: "2px" }}>
                <BiFilter size={20} />
              </span>
              Filter
            </div>
          </div>
        </div>
        <div className="beat_left">
          <div
            className={s["title"]}
            onClick={() =>
              navigate("/event-add", {
                state: {
                  pagetype: "Add",
                },
              })
            }
          >
            <IoMdAdd /> Event
          </div>
        </div>
      </div>
      <div className="beat_table">
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Image</StyledTableCell>
              <StyledTableCell align="center">Name</StyledTableCell>
              <StyledTableCell align="center">Location</StyledTableCell>
              <StyledTableCell align="center">Hindi Location</StyledTableCell>
              <StyledTableCell align="center">INR Price</StyledTableCell>
              <StyledTableCell align="center">Status</StyledTableCell>
              <StyledTableCell align="center">show Date</StyledTableCell>
              <StyledTableCell align="center">Priority</StyledTableCell>
              <StyledTableCell align="center">Created</StyledTableCell>
              <StyledTableCell align="center">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allEvents?.map((row, index) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell>
                  <img
                    src={getBaseUrl2() + row?.thumbnail[0]?.url}
                    alt="thumbnail"
                    style={{ width: "50px", height: "50px" }}
                  />
                </StyledTableCell>
                <StyledTableCell>{row?.name} </StyledTableCell>
                <StyledTableCell>{row?.location} </StyledTableCell>
                <StyledTableCell>{row?.hindiLocation} </StyledTableCell>
                <StyledTableCell>{row?.price} </StyledTableCell>
                <StyledTableCell align="center">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => event_status(row)}
                    className={`${
                      row.status === "Active"
                        ? s.active_admin
                        : s.inactive_admin
                    }`}
                  >
                    {row.status === "Active" ? "Active" : "Inactive"}
                    {/* {row?.status} */}
                  </div>
                </StyledTableCell>
                <StyledTableCell>{row?.date?.slice(0, 10)}</StyledTableCell>
                <StyledTableCell>{row?.priority}</StyledTableCell>
                <StyledTableCell>
                  {row?.created_at?.slice(0, 10)}
                </StyledTableCell>
                <StyledTableCell align="center">
                  <CiEdit
                    onClick={() =>
                      navigate("/event-add", {
                        state: {
                          pagetype: "Edit",
                          data: row,
                        },
                      })
                    }
                    style={{
                      fontSize: "1rem",
                      color: "var(--clr-primary)",
                      marginRight: "0.5rem",
                      cursor: "pointer",
                    }}
                  />
                  <MdDelete
                    onClick={() => {
                      setdeletePopup(true);
                      setcurrentGroup(row);
                      setdeleteId(row._id);
                    }}
                    style={{
                      fontSize: "1rem",
                      color: "var(--clr-primary)",
                      cursor: "pointer",
                    }}
                  />
                </StyledTableCell>
                {/* <StyledTableCell align="center">
                  <div className={s["astrologer-action-btn"]}>
                    <span
                      onClick={() =>
                        navigate("/event-add", {
                          state: {
                            pagetype: "Edit",
                            data: row,
                          },
                        })
                      }
                    >
                      Edit
                    </span>
                    <span
                      onClick={() => {
                        setdeletePopup(true);
                        setcurrentGroup(row);
                        setdeleteId(row._id);
                      }}
                    >
                      Delete
                    </span>
                   
                  </div>
                  
                </StyledTableCell> */}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        {allEvents?.length <= 0 && <DataNotFound />}
        {allEvents?.length > 0 && (
          <div className={s["pagination"]}>
            <Pagination
              count={pageLength}
              size="large"
              style={{ color: "#D21903" }}
              onChange={(e, value) => setpageCount(value)}
              page={pageCount}
            />
          </div>
        )}
      </div>
      <Dialog
        open={deletePopup}
        aria-labelledby="form-dialog-title"
        maxWidth="xs"
        fullWidth="true"
        onClose={() => setdeletePopup(false)}
      >
        <DialogTitle className={s.dialog_title}>
          <div>Do you want to delete {currentGroup.name}?</div>
        </DialogTitle>
        <DialogContent className={s.cardpopup_content}>
          <div style={{ display: "flex", gap: "1rem" }}>
            <div
              className={s.employee_gl_popup}
              onClick={() => setdeletePopup(false)}
            >
              Cancel
            </div>
            <div
              className={s.employee_gl_popup_del}
              onClick={() => deleteastroFunc()}
            >
              Delete
            </div>
          </div>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
      <Loder loading={isLoading} />
    </div>
  );
};

export default EventList;
