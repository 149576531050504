import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { tableCellClasses } from "@mui/material/TableCell";
import s from "./adminTransaction.module.css";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Pagination,
} from "@mui/material";
import { notificationHandler } from "../../utils/Notification";
import Loder from "../../Loder/Loder";
import { BiFilter, BiSearch } from "react-icons/bi";
import DataNotFound from "../ErrorPage/DataNotFound";
import { IoMdAdd } from "react-icons/io";
import { getadiminTransaction_api } from "../api/adminTransaction";
import UploadVideoUrl from "../Users/UploadVideoUrl";
import xlsx from "json-as-xlsx";
import { toast } from "react-toastify";

const AdminTranactionList = () => {
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const [adiminTransaction, setadiminTransaction] = useState([]);
  const [pageCount, setpageCount] = useState(1);
  const [deleteId, setdeleteId] = useState("");
  const [deletePopup, setdeletePopup] = useState(false);
  const [currentGroup, setcurrentGroup] = useState({});
  const [pageLength, setpageLength] = useState();
  const [search, setsearch] = useState("");
  const [slots, setSlots] = useState([{}]);

  const [userId, setuserId] = useState("");

  const [uploadVideoModal, setuploadVideoModal] = useState(false);
  const [transactionId, settransactionId] = useState("");
  useEffect(() => {
    fetchadiminTransactionFunc();
  }, [pageCount, search]);

  async function fetchadiminTransactionFunc() {
    setisLoading(true);
    try {
      const temp = {
        page: pageCount,
        limit: 10,
        search: search.trim(),
      };
      let res = await getadiminTransaction_api(temp);
      if (res.data.status) {
        setadiminTransaction(res?.data?.data);
        setpageLength(res?.data?.meta?.totalPages);
        setisLoading(false);
      } else {
        setisLoading(false);
        console.log("status false!");
      }
    } catch (error) {
      console.log(error);
    }
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "var(--clr-theme)",
      color: theme.palette.common.white,
      fontWeight: "bold",
      borderRight: "1px solid #fff",
      overflow: "hidden",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      border: "none",
      borderLeft: "2px solid #00000011",
      "&:last-child": {
        borderRight: "2px solid #00000011",
      },
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    borderBottom: "2px solid #00000011",
  }));

  //  This is used to export the data in the excel file

  let settings = {
    fileName: "Transaction List", // Name of the resulting spreadsheet
    extraLength: 3, // A bigger number means that columns will be wider
    writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
    writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
    // RTL: true, // Display the columns from right-to-left (the default value is false)
  };

  const exportTransactionFunc = (adiminTransaction) => {
    if (adiminTransaction.length < 1) {
      return toast.alert("No Data available foe export");
    }
    let data = [
      {
        sheet: "Users Data",
        columns: [
          {
            label: "Created Date",
            value: (row) =>
              row?.created_at ? row?.created_at?.slice(0, 10) : "",
          },
          {
            label: "Status",
            value: (row) => (row?.status ? row?.status : ""),
          },
          {
            label: "Event Price",
            value: (row) => (row?.eventId ? row?.eventId?.price : ""),
          },
          {
            label: "Event Date",
            value: (row) =>
              row?.eventId ? String(row?.eventId?.date).slice(0, 10) : "",
          },
          {
            label: "Event Name",
            value: (row) => (row?.eventId ? row?.eventId?.name : ""),
          },
          {
            label: "User Phone No.",
            value: (row) => (row?.userId ? row?.userId?.phone : ""),
          },
          {
            label: "User Name",
            value: (row) => (row?.userId ? row?.userId?.name : ""),
          },
          {
            label: "Transaction ID",
            value: (row) => (row?.transactionId ? row?.transactionId : ""),
          },
          {
            label: "Order ID",
            value: (row) => (row?.orderId ? row?.orderId : ""),
          },
          {
            label: "Payment ID",
            value: (row) => (row?.paymentId ? row?.paymentId : ""),
          },
        ],
        content: adiminTransaction,
      },
    ];
    let callback = function (sheet) {
      console.log("Download complete:", sheet);
    };
    try {
      xlsx(data, settings, callback);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="">
      <div className="beat_heading">
        <div className={s["user-list-heading"]}>
          <div className="user-list-title">
            <h3>Transaction List</h3>
          </div>
          <div className={s["title"]}>
            <span
              onClick={() => exportTransactionFunc(adiminTransaction)}
              style={{ paddingRight: "2px" }}
            >
              Export to Excel
            </span>
          </div>
        </div>
      </div>
      <div className="beat_table" style={{ overflowX: "auto" }}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {/* <StyledTableCell align="center">Image</StyledTableCell> */}
              <StyledTableCell align="center">Transaction ID</StyledTableCell>
              <StyledTableCell align="center">
                Transaction TIming
              </StyledTableCell>
              <StyledTableCell align="center">Order Id</StyledTableCell>
              <StyledTableCell align="center">User Name</StyledTableCell>
              <StyledTableCell align="center">Contact Number</StyledTableCell>
              <StyledTableCell align="center">Puja Id</StyledTableCell>
              <StyledTableCell align="center" style={{ minWidth: "300px" }}>
                Puja Name
              </StyledTableCell>
              <StyledTableCell align="center" style={{ minWidth: "120px" }}>
                Puja Date
              </StyledTableCell>
              <StyledTableCell align="center">Amount</StyledTableCell>
              <StyledTableCell align="center">Status</StyledTableCell>
              <StyledTableCell align="center">Upload Video</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {adiminTransaction?.map((row) => (
              <StyledTableRow key={row.id}>
                {/* <StyledTableCell>
                                    {row?.item?.thumbImage ? <img style={{ height: "2rem", width: "3rem" }} src={getBaseUrl2() + row?.item?.thumbImage} alt="" /> : null}
                                </StyledTableCell> */}
                <StyledTableCell align="center">
                  {row?.transactionId}{" "}
                </StyledTableCell>
                <StyledTableCell align="center">
                  <div>
                    <p>{row?.created_at?.slice(0, 10)}</p>
                    <p>{new Date(row?.created_at).toLocaleTimeString()}</p>
                  </div>
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row?.orderId}{" "}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row?.userId?.name}{" "}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row?.userId?.phone}{" "}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row?.eventId?._id}{" "}
                </StyledTableCell>
                <StyledTableCell align="center" style={{ width: "300px" }}>
                  {row?.eventId?.name}{" "}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row?.eventId?.date.slice(0, 10)}{" "}
                </StyledTableCell>
                <StyledTableCell align="center">{row?.amount} </StyledTableCell>
                <StyledTableCell align="center">
                  <div
                    style={{ cursor: "pointer" }}
                    // onClick={() => banner_status(bankDetail)}
                    className={`${
                      row.status === "pending"
                        ? s.inactive_admin
                        : s.active_admin
                    }`}
                  >
                    {row?.status}
                  </div>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <div
                    className={s["title"]}
                    onClick={() => {
                      settransactionId(row._id);
                      setuserId(row?.userId?._id);
                      setuploadVideoModal(true);
                    }}
                  >
                    UPLOAD
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        {adiminTransaction?.length <= 0 && <DataNotFound />}
      </div>
      {adiminTransaction?.length > 0 && (
        <div className={s["pagination"]}>
          <Pagination
            count={pageLength}
            size="large"
            style={{ color: "#D21903" }}
            onChange={(e, value) => setpageCount(value)}
            page={pageCount}
          />
        </div>
      )}
      <Dialog
        open={deletePopup}
        aria-labelledby="form-dialog-title"
        maxWidth="xs"
        fullWidth="true"
        onClose={() => setdeletePopup(false)}
      >
        <DialogTitle className={s.dialog_title}>
          <div>Do you want to delete {currentGroup.category_name}?</div>
        </DialogTitle>
        <DialogContent className={s.cardpopup_content}>
          <div style={{ display: "flex", gap: "1rem" }}>
            <div
              className={s.employee_gl_popup}
              onClick={() => setdeletePopup(false)}
            >
              Cancel
            </div>
            <div className={s.employee_gl_popup_del}>Delete</div>
          </div>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
      <Loder loading={isLoading} />
      <UploadVideoUrl
        open={uploadVideoModal}
        setOpen={setuploadVideoModal}
        userId={userId}
        subscriptionTransactionId={transactionId}
        type={"transaction"}
      />
    </div>
  );
};

export default AdminTranactionList;
